import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/work",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "work.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "/certifications",
        name: "Certifications",
        component: () => import("@/modules/work/certifications/views/TheCertifications.vue"),
      },
    ],
  },
];
